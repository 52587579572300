import Paragraph from "@components/elements/paragraph"
import React, { useState } from "react"
import { TabContainer, TabPanel } from "../tab"
import Flex from "@components/elements/flex"
import Div from "@components/elements/div"
import Heading from "@components/elements/heading"
import GradientText from "@components/common/gradient-text"
import styled, { useTheme } from "styled-components"
import Image from "@components/image"
import media from "../../../../styles/media"
import { motion } from "framer-motion"
import PropTypes from "prop-types"
import Anchor from "@components/elements/anchor"
import { graphql, useStaticQuery } from "gatsby"
import { processImages } from "@utils/process-images"

const HeroWrapperGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  grid-template-areas: "stack";
`

const AnimatedHeroWrapper = styled(motion.div)`
  grid-area: stack;

  .klipfolio-image-wrapper div {
    border-radius: 1rem;

    ${media.sm`
      border-radius: 0.5rem;
    `}
  }
`

const HeroWrapper = ({ active, shadow, image }) => (
  <AnimatedHeroWrapper
    variants={{
      active: {
        opacity: 1,
      },
      inactive: {
        opacity: 0,
      },
    }}
    style={{ display: active ? null : "none" }}
    animate={active ? "active" : "inactive"}
  >
    <Image
      borderStyle={{
        boxShadow: shadow,
      }}
      radius={1}
      file={image}
    />
  </AnimatedHeroWrapper>
)

HeroWrapper.propTypes = {
  active: PropTypes.bool.isRequired,
  shadow: PropTypes.string.isRequired,
  image: PropTypes.object.isRequired,
}

export const PowerMetricsFeatureGallery = ({ handleClick }) => {
  const { imageData } = useStaticQuery(graphql`
    query PowerMetricsFeatureGallery {
      imageData: allDirectusFiles(
        filter: {
          directus_id: {
            in: [
              "6147cc51-b3b9-4447-8912-35d408bd1ddf"
              "dab92c3d-c3ca-4601-846a-18b8bfbe05de"
              "adba6b41-06da-4e6a-a524-2f989a097637"
              "730ecad8-990c-4691-b1c8-ee041985a88b"
              "60991c46-b3e2-4ac5-90a7-c372e347ef35"
              "636649f5-7a25-48f3-818b-4e1d888cb5bf"
              "9aff4ef8-b1b6-4342-ab28-c537fcb54ec6"
              "7716872c-e62c-4dcb-8ba8-8219d8f2523a"
            ]
          }
        }
      ) {
        edges {
          node {
            directus_id
            cdn
            id
            placeholder
            title
          }
        }
      }
    }
  `)

  const images = processImages(imageData.edges)

  const [heroOne, setHeroOne] = useState(0)
  const [heroTwo, setHeroTwo] = useState(0)
  const [heroThree, setHeroThree] = useState(0)

  const { shadow } = useTheme()

  return (
    <Flex
      textContainer
      gap="12rem"
      gapSm="4rem"
      margin="12rem auto"
      marginSm="4rem auto"
    >
      <Div>
        <Heading id="self-serve" as="h5" margin="0 0 0.5rem">
          Independent, AI-powered consumption
        </Heading>
        <Heading as="h2" fontSize="3rem" margin="0 0 1.5rem">
          Go from self-serve to
          <br />
          <GradientText gradient="linear-gradient(90deg, #FFB85C 0%, #FDA1B9 40%, #707EFA 140%)">
            self-sufficiency.
          </GradientText>
        </Heading>
        <Paragraph margin="0 0 1rem">
          Get quick answers to questions. With just a few clicks or by asking
          natural-language questions, business users get the data they need for
          daily decision making, dashboarding and reporting. Data teams may
          never hear from them again ... Except when they say Thank You!
        </Paragraph>
        <Anchor onClick={handleClick} fontSize="1.2rem" arrow>
          Public API available, contact us for details
        </Anchor>
        <TabContainer
          tabs={[
            "PowerMetrics AI",
            "Analysis & dashboards",
            "Goals & notifications",
          ]}
          activeIndex={heroThree}
          setActiveIndex={setHeroThree}
        />

        <Div
          borderRadius="1rem"
          background={"#F7F8FF"}
          padding="4rem"
          paddingSm="2rem"
        >
          <HeroWrapperGrid>
            <HeroWrapper
              key="heroThree-0"
              active={heroThree === 0}
              shadow={shadow.dramatic}
              image={images["636649f5-7a25-48f3-818b-4e1d888cb5bf"]}
            />
            <HeroWrapper
              key="heroThree-1"
              active={heroThree === 1}
              shadow={shadow.dramatic}
              image={images["9aff4ef8-b1b6-4342-ab28-c537fcb54ec6"]}
            />
            <HeroWrapper
              key="heroThree-2"
              active={heroThree === 2}
              shadow={shadow.dramatic}
              image={images["7716872c-e62c-4dcb-8ba8-8219d8f2523a"]}
            />
          </HeroWrapperGrid>
        </Div>

        <TabPanel index={0} activeIndex={heroThree}>
          <Paragraph margin="1rem 0">
            Have a conversation with your business data. PowerMetrics includes a
            natural language interface that understands your intentions and uses
            generative AI to build the visualizations that answer your
            questions.
          </Paragraph>
        </TabPanel>
        <TabPanel index={1} activeIndex={heroThree}>
          <Paragraph margin="1rem 0">
            From general exploration to advanced features, like performing
            forecasting and expected range analyses, business users get valuable
            insights in just a few clicks, without seeing a single line of code.
          </Paragraph>
          <Paragraph margn="1rem 0">
            PowerMetrics puts dashboard and report creation where they belong -
            in the capable hands of business users.
          </Paragraph>
        </TabPanel>
        <TabPanel index={2} activeIndex={heroThree}>
          <Paragraph margin="1rem 0">
            Set recurring or threshold goals and be informed of changes in your
            data with in-app and/or email notifications.
          </Paragraph>
        </TabPanel>
      </Div>

      <Div>
        <Heading as="h5" margin="0 0 0.5rem">
          Single source of truth
        </Heading>
        <Heading as="h2" fontSize="3rem" margin="0 0 1.5rem">
          Define once,
          <br />
          <GradientText gradient="linear-gradient(90deg, #FFB85C 0%, #FDA1B9 40%, #707EFA 140%)">
            use everywhere.
          </GradientText>
        </Heading>
        <Paragraph>
          Metrics are predefined calculations that align with a business concept
          (for example “revenue”). Centrally stored, they give business users
          the trusted data they need to work with confidence.
        </Paragraph>
        <TabContainer
          tabs={["Metric creation", "Maintains the SSOT", "MetricHQ"]}
          activeIndex={heroOne}
          setActiveIndex={setHeroOne}
        />
        <Div
          borderRadius="1rem"
          background={"#F7F8FF"}
          padding="4rem"
          paddingSm="2rem"
        >
          <HeroWrapperGrid>
            <HeroWrapper
              key="heroOne-0"
              active={heroOne === 0}
              shadow={shadow.dramatic}
              image={images["6147cc51-b3b9-4447-8912-35d408bd1ddf"]}
            />
            <HeroWrapper
              key="heroOne-1"
              active={heroOne === 1}
              shadow={shadow.dramatic}
              image={images["dab92c3d-c3ca-4601-846a-18b8bfbe05de"]}
            />
            <HeroWrapper
              key="heroOne-2"
              active={heroOne === 2}
              shadow={shadow.dramatic}
              image={images["adba6b41-06da-4e6a-a524-2f989a097637"]}
            />
          </HeroWrapperGrid>
        </Div>

        <TabPanel index={0} activeIndex={heroOne}>
          <Paragraph margin="1rem 0">
            Choose one or more of our&nbsp;
            <Anchor link={"/powermetrics/integrations"}>
              integration solutions
            </Anchor>
            &nbsp;and add or replace methods as your data stack evolves. All
            metrics, regardless of the chosen solution, can be used
            interchangeably as calculated metrics.
          </Paragraph>
        </TabPanel>
        <TabPanel index={1} activeIndex={heroOne}>
          <Paragraph margin="1rem 0">
            No more data duplication and inconsistencies. Depending on the&nbsp;
            <Anchor link="/powermetrics/integrations">
              integration method
            </Anchor>
            , data is stored in one place - your data warehouse, your semantic
            layer or in PowerMetrics.
          </Paragraph>
        </TabPanel>
        <TabPanel index={2} activeIndex={heroOne}>
          <Paragraph margin="1rem 0">
            An open dictionary for metrics and KPIs where you&apos;ll find
            hundreds of industry-standard metric definitions and pre-built
            metric templates. You can also take part in the metrics community by
            contributing your own metric definitions.&nbsp;
            <Anchor target="_blank" link="https://www.metrichq.org/">
              Visit MetricHQ
            </Anchor>
            .
          </Paragraph>
        </TabPanel>
      </Div>
      <Div>
        <Heading as="h5" margin="0 0 0.5rem">
          A catalog of metrics
        </Heading>
        <Heading as="h2" fontSize="3rem" margin="0 0 1.5rem">
          Bridge the gap between
          <GradientText gradient="linear-gradient(90deg, #FFB85C 0%, #FDA1B9 40%, #707EFA 140%)">
            data and business.
          </GradientText>
        </Heading>
        <Paragraph>
          Reach new levels of cross-team efficiency with a centralized catalog
          of curated metrics. Governance for the data team. Independence for
          business users.
        </Paragraph>

        <TabContainer
          tabs={["Metric governance", "Metric access"]}
          activeIndex={heroTwo}
          setActiveIndex={setHeroTwo}
        />

        <Div
          borderRadius="1rem"
          background={"#F7F8FF"}
          padding="4rem"
          paddingSm="2rem"
        >
          <HeroWrapperGrid>
            <HeroWrapper
              key="heroTwo-0"
              active={heroTwo === 0}
              shadow={shadow.dramatic}
              image={images["730ecad8-990c-4691-b1c8-ee041985a88b"]}
            />
            <HeroWrapper
              key="heroTwo-1"
              active={heroTwo === 1}
              shadow={shadow.dramatic}
              image={images["60991c46-b3e2-4ac5-90a7-c372e347ef35"]}
            />
          </HeroWrapperGrid>
        </Div>

        <TabPanel index={0} activeIndex={heroTwo}>
          <Paragraph margin="1rem 0">
            Control access to the organization’s metrics with user roles and
            view and edit permissions. After defining metrics, set them as
            “certified” to ensure business teams are using the right data across
            the board.
          </Paragraph>
        </TabPanel>
        <TabPanel index={1} activeIndex={heroTwo}>
          <Paragraph margin="1rem 0">
            See every metric in a summarized, scorecard-like view. Quickly
            understand a metric’s performance and compare its current value to
            previous periods to see how it’s trending over time - all in one
            place.
          </Paragraph>
        </TabPanel>
      </Div>
    </Flex>
  )
}

PowerMetricsFeatureGallery.propTypes = {
  handleClick: PropTypes.func.isRequired,
}
