import React, { useCallback, useState } from "react"
import PropTypes from "prop-types"
import Container from "@components/layout/container"
import { graphql } from "gatsby"
import { processImages } from "@utils/process-images"
import { useTheme } from "styled-components"
import Flex from "@components/elements/flex"
import Heading from "@components/elements/heading"
import Paragraph from "@components/elements/paragraph"
import Button from "@components/elements/button"
import Div from "@components/elements/div"
import Image from "@components/image"
import Span from "@components/elements/span"
import Layout from "@components/layout"
import { Substack } from "@components/cta/substack"
import { SubstackLogo } from "@components/svg/substack-logo"
import { PowerMetricsEOPCta } from "@components/cta/eop/powermetrics"
import { ServicesBlock } from "@components/common/services-block"
import { PowerMetricsFeatureGallery } from "@components/pages/powermetrics/sections/feature-gallery"
import { GetADemoForm } from "@components/forms/get-a-demo"
import { PowerMetricsTechnicalGallery } from "@components/pages/powermetrics/sections/technical-gallery"
import { PowerMetricsHeroAnimationDark } from "@components/pages/powermetrics/hero-animation"
import { PowerMetricsSocialProof } from "@components/pages/powermetrics/sections/social-proof"
import { PowerMetricsPitch } from "@components/pages/powermetrics/sections/pitch"
import { PowerMetricsFAQ } from "@components/pages/powermetrics/sections/faq"
import { HighGradient } from "@components/common/gradient"
import { useGradient } from "@hooks/use-gradient"

const PowermetricsPage = ({ data }) => {
  const images = processImages(data.images.edges)
  const { color, shadow } = useTheme()
  const { gradient } = useGradient()

  const [modalOpen, setModalOpen] = useState(false)
  const handleClick = useCallback(() => {
    setModalOpen(true)
  }, [setModalOpen])

  return (
    <Layout
      titleExtension="Klipfolio PowerMetrics"
      seo={{
        title: "Make Analytics Everybody’s Business",
        description: `PowerMetrics helps data teams unlock the value in their data stack and make working with metrics effortless for everyone. Provide a catalog of trusted metrics.`,
      }}
      fullWidth
      marginless
      hideOverflow
      login
    >
      <GetADemoForm modalOpen={modalOpen} setModalOpen={setModalOpen} />
      <Container fullWidth noPadding>
        <Div position="relative" overflow='hidden' background={color.indigo700}>
          <Flex position="absolute" width="100%" zIndex="0" alignItems="center">
            <Flex minWidth="1600px" width="100%">
              <HighGradient top={"6rem"} src={gradient.cdn} />
            </Flex>
          </Flex>
          <Flex position="absolute" width="100%" zIndex="0" alignItems="center">
            <Image
              file={images["95fb17ea-830e-4f27-9875-ad562f3f0332"]}
              width={1600}
            />
          </Flex>

          <Flex padding="0 0 6rem" position="relative" zIndex={1}>
            <Flex
              padding="0 1rem 0"
              margin="6rem auto 0"
              marginSm="4rem auto 0"
              center
            >
              <Heading
                mobileScale={0.5}
                color="white"
                fontSize="4rem"
                center
                margin={"2rem 0 1rem"}
              >
                Make analytics
                <br />
                everybody&apos;s business.
              </Heading>
              <Paragraph
                color="white"
                fontWeight={600}
                fontSize="1.4rem"
                lineHeight={1.5}
                maxWidth="800px"
                margin="0 auto 2rem"
                center
              >
                Put data analysis and dashboard creation into the hands of
                business users with curated metrics, governed by the data team.
              </Paragraph>
            </Flex>

            <Button.PowerMetrics force margin="0 auto" data-cta-location="header">
              Get Started Free
            </Button.PowerMetrics>

            <PowerMetricsHeroAnimationDark />

            <PowerMetricsSocialProof />
          </Flex>
        </Div>

        <PowerMetricsTechnicalGallery />

        <PowerMetricsFeatureGallery handleClick={handleClick} />

        <PowerMetricsPitch />

        <Div margin="8rem auto" marginSm="4rem auto">
          <ServicesBlock />
        </Div>

        <Flex
          borderRadius="1rem"
          borderRadiusSm="0"
          container
          background="linear-gradient(180deg, #182026 0%, #014C43 90%, #08261B 180%)"
          padding="4rem 2rem"
          paddingSm="2rem 1rem"
          margin="0 auto 8rem"
          marginSm="0 auto 4rem"
          boxShadow={shadow.dramatic}
          center
        >
          <Div hideOnSm>
            <Image
              style={{ maxWidth: "1300px", margin: "0 0 2rem" }}
              eager
              file={images["568fc23b-9021-4663-9fab-13bab23f423b"]}
            />
          </Div>
          <Div margin="1rem auto">
            <SubstackLogo />
          </Div>
          <Heading center as="h2" color="white" margin="0 0 2rem">
            Analytics – reimagined
          </Heading>
          <Paragraph
            center
            color="white"
            maxWidth="800px"
            margin="0 auto 2rem"
            lineHeight={1.6}
          >
            <b>Subscribe to the Metric Stack Newsletter</b>: Follow today&apos;s
            thought leaders as they share insights on working with a
            metrics/semantic layer, metric catalog, and metric-centric
            analytics, all within the modern data stack.
          </Paragraph>
          <Substack />
          <Span color="white" fontSize="0.9rem" opacity={0.6}>
            Free, delivered to your inbox every few weeks.
          </Span>
        </Flex>

        <PowerMetricsFAQ handleClick={handleClick} />
      </Container>
      <PowerMetricsEOPCta force heading={"Make analytics everybody's business."} />
    </Layout>
  )
}

PowermetricsPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default PowermetricsPage

export const pageQuery = graphql`
  query PowerMetricsMainQuery {
    images: allDirectusFiles(
      filter: {
        directus_id: {
          in: [
            "ddcbde46-598e-456d-a02b-86c83f8adb39"
            "568fc23b-9021-4663-9fab-13bab23f423b"
            "95fb17ea-830e-4f27-9875-ad562f3f0332"
          ]
        }
      }
    ) {
      edges {
        node {
          directus_id
          cdn
          id
          placeholder
          title
        }
      }
    }
  }
`
